import React from 'react'
import { graphql } from 'gatsby'
import Layout from '@layouts/layout'
import { Banner, Block, Breadcrumbs } from '@components'
import ModuleArea from '@helpers/moduleArea'
import { HelmetDatoCms } from 'gatsby-source-datocms'

const IndexPage = ({ data, pageContext }) => {
  const {
    banner,
    mobileBanner,
    bannerHeadingNode,
    bannerCaption,
    link,
    moduleArea,
    seoMetaTags
  } = data.home

  const sources = [
    mobileBanner.fluid,
    {
      ...banner.fluid,
      media: `(min-width: 700px)`
    }
  ]

  return (
    <Layout pageType="index">
      <HelmetDatoCms defer={false} seo={seoMetaTags} />
      {banner && (
        <Banner
          banner={sources}
          heading={bannerHeadingNode?.childMarkdownRemark?.html}
          link={link}
          caption={bannerCaption}
          showScrollDown
          offset
        />
      )}
      {pageContext?.breadcrumbs && (
        <Block gutters>
          <Breadcrumbs breadcrumbs={pageContext?.breadcrumbs} />
        </Block>
      )}
      <Block>
        <ModuleArea modules={moduleArea} allPosts={data.allPosts.nodes} />
      </Block>
    </Layout>
  )
}

export default IndexPage

export const query = graphql`
  query IndexQuery {
    home: datoCmsHome {
      id
      title
      bannerHeadingNode {
        childMarkdownRemark {
          html
        }
      }
      bannerCaption
      link {
        ...Link
      }
      banner {
        fluid(
          maxWidth: 2680
          imgixParams: { w: "2680", h: "1535", fit: "crop" }
        ) {
          ...GatsbyDatoCmsFluid
        }
      }
      mobileBanner: banner {
        fluid(maxWidth: 375, imgixParams: { w: "375", h: "650", fit: "crop" }) {
          ...GatsbyDatoCmsFluid
        }
      }
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      ...IndexPageContentModuleArea
    }
    allSitePage {
      nodes {
        ...MenuHelpersAllPages
      }
    }

    allPosts: allDatoCmsPost(
      sort: { fields: meta___firstPublishedAt, order: ASC }
    ) {
      nodes {
        ...PostCard
      }
    }
  }
`
